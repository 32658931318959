.option_second {
  .mat-form-field-infix {
    border-top: 0 !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0 !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding: 0 !important;
  }
}

.darkModal {
  .option_second {
    .mat-select-value {
      color: rgb(255, 255, 255) !important;
      width: auto !important;
      margin-right: 10px;
      font-size: 15px !important;
      font-weight: 400 !important;
    }

    .mat-select-arrow {
      color: rgb(255, 255, 255) !important;
    }
  }
}

.lightModal {
  .option_second {
    .mat-select-value {
      color: rgb(39 24 13) !important;
      width: auto !important;
      margin-right: 10px;
      font-size: 15px !important;
      font-weight: 400 !important;
    }

    .mat-select-arrow {
      color: rgb(39 24 13) !important;
    }
  }
}
