@import './variables';

.card_table {
    margin-top: 15px;
    width: 100%;
    border-radius: 10px;
    overflow: auto;

    th {
        color: var(--main-color) !important;
    }

    td.mat-cell {
        color: var(--main-color) !important;
    }
}

table.mat-table {
    margin-bottom: 20px;
    min-width: 1100px;
    background: transparent;
}

.table_scroll {
    overflow-x: auto;
    margin-bottom: 20px;
}


:host ::ng-deep th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-width: 0 !important;
}

:host ::ng-deep th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding: 8px 5px 0 15px !important;
}