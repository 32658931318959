@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~bootstrap/dist/css/bootstrap.min.css";

@import "./styles/variables";
@import "./styles/input.scss";
@import "./styles/mat-expansion.scss";
@import "./styles/mat-option.scss";
@import "./styles/table-stripes.scss";
@import "./styles/table.scss";

.material-icons {
  font-family: "Material Icons" !important;
}

.icon {
  cursor: pointer;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

re-captcha.is-invalid > div {
  border: 1px solid #dc3545 !important;
  border-radius: 0.2rem;
}

.svg-inline--fa {
  display: inline-block;
  height: 1.3em;
  overflow: visible;
  vertical-align: -0.125em;
}

html,
body,
table {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
}

h2 {
  margin: 0 !important;
}

main {
  padding: 110px 3% 50px;
  width: 100%;
  min-height: 100vh;
}

.card_loading {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgb(255 255 255 / 87%);
}

.msg_erro {
  font-size: 10px !important;
  color: #ff3535 !important;
  margin-left: 10px;
}

.msg_error {
  text-align: center;
  font-size: 12px;
  color: #ff522a;
  margin-top: 10px;
}

/* Nao remover! Hotfix pra fazer mat-select funcionar dentro de modal */
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 10000000 !important;
}

.drawerDark {
  box-shadow: 5px 0px 20px #00000029;
  border-right: none;
  background: #292929 !important;
}

.drawerLight {
  box-shadow: 0px 3px 6px #00000029;
  border-right: none;
  background: #f9f9f9 !important;
}

.darkMode {
  background: #292929 !important;
  transition: all 0.5s;
}

.lightMode {
  background: #f9f9f9 !important;
  transition: all 0.5s;
}

.darkMode2 {
  background: #303030 !important;
  transition: all 0.5s;
}

.lightMode2 {
  background: #ffffff !important;
  transition: all 0.5s;
}

.darkModal {
  background: #303030 !important;
  transition: all 0.5s;
}

.lightModal {
  background: white !important;
  transition: all 0.5s;
}

.btn_brown {
  background-color: #171717;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  border: 0px solid transparent !important;
  border-radius: 25px !important;
}

.btn_gray {
  background-color: #ada7a3;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  border: 0px solid transparent;
  border-radius: 25px !important;
}

.btn_white {
  background-color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  color: #171717;
  border: 0px solid transparent;
  border-radius: 25px !important;
}

/* --- start card input --- */
.card_input_title span {
  font-weight: bold;
  font-size: 13px;
  height: 22px;
}

.card_input_title {
  width: 100%;
}

.card_input_title input {
  width: 100%;
  margin: 5px 0 10px;
  padding: 7px 15px;
  border: 1px solid;
  border-radius: 10px;
}

.card_input input.darkmode,
.card_input input.darkModal {
  border: 1px solid #ffffff !important;
}

.card_input input.lightmode,
.card_input input.lightModal {
  border: 1px solid #dbdbdb !important;
}

.darkMode input::placeholder {
  color: #ffffff !important;
}

.darkModal input::placeholder {
  color: #171717 !important;
}

.lightMode input::placeholder,
.lightModal input::placeholder {
  color: #171717 !important;
}

/* START STYLE SELECT */

/* START STYLE SELECT */
.card_input .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(70, 71, 75, 0) !important;
}

.card_input .mat-form-field {
  border-radius: 8px !important;
  border-color: transparent;
}

.card_input .mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 8px !important;
  border-color: transparent;
  padding: 0 !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0;
}

.card_input .mat-form-field-infix {
  padding: 6px 0 !important;
  border-top: 0px solid transparent !important;
}

::ng-deep .card_input .mat-form-field-underline {
  position: absolute !important;
  width: 0px !important;
  pointer-events: none !important;
  transform: none !important;
}

.card_input .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: translateY(0) !important;
}

.card_input .mat-select-value {
  color: var(--main-color);
  font-size: 13px;
  font-weight: 500;
}

.card_input .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: var(--main-color);
}

.card_input .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--main-color);
}

/* END STYLE SELECT */

.darkModal .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffffff;
}

.darkModal .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #ffffff;
}

.darkModal .mat-radio-outer-circle {
  border-color: rgb(255 255 255 / 54%);
}

.lightModal .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #303030;
}

.lightModal .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #303030;
}

.lightModal .mat-radio-outer-circle {
  border-color: rgba(48, 48, 48, 0.54);
}

/* --- end card input --- */

.font25 {
  font-size: 25px !important;
  font-weight: bold !important;
}

.font16 {
  font-size: 17px;
  font-weight: bold;
}

.font14 {
  font-size: 14px;
  font-weight: bold;
}

.font13 {
  font-size: 13px;
  font-weight: bold;
}

.font100 {
  font-weight: 100;
  font-size: 14px;
}

.text {
  font-size: 22px;
  font-weight: 100px;
}

.sub_text {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 0 !important;
}

textarea:focus,
input:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

a,
button {
  cursor: pointer;
}

textarea {
  resize: none;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #269f10 !important;
}

/* scrollbar */
.mat-drawer-content,
.mat-drawer-content-x {
  overflow-y: auto;
  scrollbar-width: thin;
}

.mat-drawer-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.mat-drawer-content-x::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.mat-drawer-content::-webkit-scrollbar-track,
.mat-drawer-content-x::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 7px;
}

.mat-drawer-content::-webkit-scrollbar-thumb,
.mat-drawer-content-x::-webkit-scrollbar-thumb {
  background: #303030 0% 0% no-repeat padding-box;
  background: #303030 0% 0% no-repeat padding-box;
  border-radius: 7px;
}

.darkMode mat-icon {
  color: #ffffff !important;
}

.lightMode mat-icon {
  color: #281e16 !important;
}

.darkModal
  .mat-radio-button.mat-accent.mat-radio-checked
  .mat-radio-outer-circle {
  border-color: #ffffff;
}

.darkModal .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #ffffff;
}

.darkModal .mat-radio-outer-circle {
  border-color: rgb(255 255 255 / 54%);
}

.lightModal
  .mat-radio-button.mat-accent.mat-radio-checked
  .mat-radio-outer-circle {
  border-color: #303030;
}

.lightModal .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #303030;
}

.lightModal .mat-radio-outer-circle {
  border-color: rgba(48, 48, 48, 0.54);
}

/* table */
.card_table {
  width: 100%;
}

table.mat-table {
  min-width: 900px;
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-width: 0px !important;
  border-bottom-style: transparent;
}

tr.mat-row,
tr.mat-footer-row {
  height: 55px !important;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 50px !important;
  width: 30%;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: 50px !important;
  width: 10%;
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  width: 20%;
}

.card_table table th {
  font-size: 15px;
}

.card_table .darkMode thead tr,
.card_table .darkMode2 thead tr {
  background: #494949 !important;
}

.card_table .darkMode tr,
.card_table .darkMode2 tr {
  background: #494949;
}

.card_table .darkMode tr:nth-child(odd),
.card_table .darkMode2 tr:nth-child(odd) {
  background: #7f7f7f;
}

.card_table table thead tr,
.card_table .lightMode thead tr,
.card_table .lightMode2 thead tr {
  background: #ffffff !important;
}

.card_table table tr,
.card_table .lightMode tr,
.card_table .lightMode2 tr {
  background: #ffffff;
}

.card_table table tr:nth-child(odd),
.card_table .lightMode tr:nth-child(odd),
.card_table .lightMode2 tr:nth-child(odd) {
  background: #e3e3e3;
}

.table-paginator {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 0;
}

.text_paginator {
  font-size: 12px;
  margin: 0 12px;
}

.paginator-button {
  cursor: pointer;
}

/* Scroll Table */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 7px;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background: #303030 0% 0% no-repeat padding-box;
  background: #303030 0% 0% no-repeat padding-box;
  border-radius: 7px;
}

/* ----------- ESTILOS GERAL DAS TELAS COM TABELAS ----------- */

.line_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}

.card_title {
  width: 80%;
  display: flex;
  align-items: center;
}

.card_title h2 {
  padding-right: 25px;
}

.card_title button {
  padding: 4px 40px;
}

.card_search {
  width: 250px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.card_search .input {
  position: relative;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 30px 8px 12px;
  background-color: transparent;
  border-radius: 5px;
}

.card_search .icon {
  position: absolute;
  width: 22px;
  margin-right: 10px;
}

.card_search input.darkmode {
  border: 1px solid #ffffff;
}

.card_search input.lightmode {
  border: 1px solid #dbdbdb;
}

.darkMode input::placeholder {
  color: #ffffff !important;
}

.lightMode input::placeholder {
  color: #171717 !important;
}

.card_table {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 3px #00000029;
  overflow: auto;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button {
  margin-left: 15px;
  cursor: pointer;
}

.table_paginator {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 0;
}

.text_paginator {
  font-size: 12px;
  margin: 0 12px;
}

.paginator_button {
  cursor: pointer;
}

/* START datepicker */
.mat-datepicker-toggle-active {
  color: #4f4f4f !important;
}
.mat-calendar-body-selected {
  background-color: #4f4f4f !important;
  color: #fff !important;
}
.mat-calendar-body-cell:hover,
.mat-calendar-body-cell-preview:hover,
.mat-calendar-body-cell-content.mat-focus-indicator:hover,
.mat-calendar-body-selected:hover {
  border-color: transparent !important;
  background-color: #4f4f4f7a !important;
  border-radius: 50% !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  border-color: rgba(0, 0, 0, 0) !important;
  background-color: #4f4f4f7a !important;
}

.card_date label {
  text-align: start;
  margin-bottom: 10px;
}

.card_date .mat-form-field,
.card_date .mat-form-field-wrapper {
  width: 100% !important;
}

.card_date .mat-form-field-appearance-fill .mat-form-field-flex {
  border: 1px solid #dbdbdb;
  border-radius: 10px !important;
  padding: 0 !important;
  background: white !important;
  width: 100% !important;
  /* margin-top: 10px; */
  display: flex !important;
  align-items: center !important;
}

.card_date .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0;
}

.card_date .mat-form-field-appearance-fill .mat-form-field-infix {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #171717 !important;
  padding: 8px 20px;
}

.card_date
  .mat-form-field:not(.mat-form-field-appearance-legacy)
  .mat-form-field-suffix
  .mat-icon-button {
  width: 2.5em !important;
  height: 2.2em !important;
}

::ng-deep .mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
::ng-deep .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #171717;
}

.mat-form-field-underline {
  width: 0px !important;
}

/* END STYLE SELECT */

@media screen and (max-width: 800px) {
  .line_center {
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (max-width: 660px) {
  .card_title {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}

::ng-deep .mat-option-text,
::ng-deep .mat-select-value {
  font-size: 12px !important;
  font-weight: bold !important;
}

::ng-deep .mat-form-field-appearance-legacy .mat-form-field-underline {
  width: 0px !important;
  display: none;
}

/* Check Box */

::ng-deep .darkMode2 .mat-checkbox-frame {
  border-color: #fff !important;
}

::ng-deep .lightModal2 .mat-checkbox-frame {
  border-color: #292929 !important;
}

.icons_close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 99;
}
