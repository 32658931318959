:root {
    --main-color:  #FFFFFF;
    --primary-text:  #171717;
    --second-text:  #FFFFFF;

    --primary-bg-color:  #292929;
    --second-bg-color:  #303030;

    --border: #dbdbdb;
    --bg-input: #e3e3e3;

    --circle:  #ffffff;
    --icon: #ffffff;
    --fill:  #303030;
    --stroke: #ffffff00;
    --arrow:  #FFFFFF;
    --arrow-bg: #303030;
    --checkbox: #fff;
    --table-bg: #494949;
    --table-bg_2: #7F7F7F;

    --shadow: #00000029;
    --border2: transparent
}

.ligth:root {
    --main-color: #171717;
    --primary-text: #FFFFFF;
    --second-text: #171717;

    --primary-bg-color: #F9F9F9;
    --second-bg-color: #FFFFFF;

    --border: #DBDBDB;
    --bg-input: #e3e3e3;

    --circle: #171717;
    --icon: #171717;
    --fill: #FFFFFF;
    --stroke: #dbdbdb;
    --arrow: #5e6a65;
    --arrow-bg: #ffffff;
    --checkbox: #5e6a65;
    --table-bg: #DBDBDB;
    --table-bg_2: #F3F3F3;
    --shadow: #00000029;
    --border2: #dbdbdb
}
