@import './variables';

.card_table {
  width: 100%;
}

.card_table table th {
  font-size: 15px;
}

.card_table tr:nth-child(odd){
  background: var(--table-bg_2) !important;
}

.card_table tr:nth-child(even){
  background: var(--table-bg) !important;
}

.card_table th.mat-header-cell {
  background: var(--table-bg) !important;
}

