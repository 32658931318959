
.mat-expansion-panel {
  &:not([class*="mat-elevation-z"]) {
    border-radius: 10px !important;
    background-color: var(--second-bg-color);
    box-shadow: 0px 0px 3px var(--shadow);
    transition: all 0.5s;
  }

  .mat-expansion-panel-header {
    padding: 10px 40px !important;
    box-sizing: content-box !important;
    border-radius: 10px !important;
  }

  .mat-expansion-panel-header-title header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--main-color) !important;
  }
}
