.card_input label{
    color: var(--second-text) !important;
}
.card_input input,
.card_input textarea {
  background-color: var(--bg-input) !important;
  border: 1px solid var(--border) !important;
  padding: 10px 20px !important;
  box-sizing: border-box !important;
}


.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 0.75em !important;
  display: flex;
  align-items: center;
}
